// src/config.ts

const getApiUrl = () => {
  const appEnv = process.env.REACT_APP_ENV; // Use custom environment variable

  if (appEnv === 'devlocal') {
    return 'http://localhost:8080';
  } else if (appEnv === 'development') {
    return 'https://mentormenteedev.com';
  } else if (appEnv === 'staging') {
    return 'https://mentormenteestg.com';
  } else if (appEnv === 'production') {
    return 'https://mentormenteeprod.com';
  }
  return "https://mentormenteestg.com";
};

const config = {
  apiUrl: getApiUrl(),
};

export default config;
